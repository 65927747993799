import { createRouter, createWebHistory } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '@/store/auth/authModule';
import { getCookie } from '~utils/cookies';

// eslint-disable-next-line import/no-cycle
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useAuthStore();
  const isAuth = store.getIsAuth;
  if (!isAuth) {
    await useAuthStore().autenticate();
  }
  const toCompare = store.isAuth;
  // || (getCookie('id_login') && getCookie('TK'));
  if (!toCompare && to.path !== '/login') {
    next({ path: '/login' });
  } else if (toCompare && to.path === '/login') next({ path: '/dashboard' });
  else next();
});

export default router;
