<template>
  <q-card id="landscape-layout">
    <span class="landscape-icon text-center">
      <q-icon name="screen_rotation" />
      <div>
        Оптимально, когда вертикально
      </div>
    </span>
  </q-card>
</template>

<script>
export default { name: 'LandscapeLayout' };

</script>

<script setup>

</script>

<style>
#landscape-layout {
  width: 100vw;
  height: 100vh;
  background: cadetblue;
  position: relative;
  z-index: 10000000;
  display: flex;
  color: #fff;
  font-size: 2rem;
}
.landscape-icon {
  margin: auto;
}
</style>
