import { MESSAGE_DELAY, modules } from '@/constants';
import {
  defineStore,
} from 'pinia';
// eslint-disable-next-line import/no-cycle
import Api, { Method } from '~utils/Api';
import { ConstantParams } from './types';

const MAX_LOG_LENGTH = 20;

export const useMainStore = defineStore('main', {
  state: () => ({
    constantParams: {
      id_login: 0,
      TK: '',
      IMEI: '',
      Name_app: '',
    } as ConstantParams,
    modal: false,
    maximized: true,
    modalComponent: {
      name: '',
      params: {},
    },
    message: false,
    messageContent: '',
    reportMode: false,
    requestsLog: [] as string[],
    showOnboarding: false,
  }),
  getters: {
    getConstantParams: (state) => state.constantParams,
  },
  actions: {
    requestsLine(url: string) {
      this.requestsLog.push(url);
      if (this.requestsLog.length > MAX_LOG_LENGTH) {
        this.requestsLog.shift();
      }
    },
    openModal(componentName: string, params: any) {
      if (this.modal) {
        this.closeModal();
      }
      setTimeout(() => {
        this.modal = true;
        this.modalComponent.name = componentName;
        this.modalComponent.params = params;
        this.setModalSize(Boolean(params?.maximized));
      }, 0);
    },
    setModalSize(maximized: boolean) {
      this.maximized = maximized;
    },
    closeModal() {
      this.modal = false;
      this.modalComponent = {
        name: '',
        params: {},
      };
    },
    showMessage(content: string) {
      if (!content || content === 'Ок') return;
      this.message = true;
      this.messageContent = content;
      setTimeout(() => {
        this.message = false;
        this.messageContent = '';
      }, MESSAGE_DELAY);
    },
    async dispatchEvent(eventName: string, params: any = null) {
      const [Name_event, Name_module] = eventName.split('.');
      const method = Name_event.split(':')[0] as Method;
      const data = {
        ...params,
        ...this.constantParams,
        Name_event,
        Name_module,
      };
      const result = await Api.all(method, data);
      return result;
    },
    clearConstantParams() {
      // $ResetPinia();
      // this.constantParams = {
      //   id_login: 0,
      //   TK: '',
      //   IMEI: '',
      //   Name_app: '',
      // };
    },
    setConstantParams(params: ConstantParams) {
      console.log(params);
      this.constantParams = params;
    },

    async sendBugReport(context: any) {
      const params = {
        Name_module: 'module_reports',
        Name_event: 'POST:report',
        text_report: context.text,
        type_report: 0,
        more_data: JSON.stringify(this.requestsLog),
        ...this.getConstantParams,
      };
      const result = await Api.post(params, true);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          const uplParams = {
            id: data[0].id_report,
            file: context.file,
          };
          this.uploadBugReportFile(uplParams);
          return true;
        }
      }
      return false;
    },
    async uploadBugReportFile(context: any) {
      const params = JSON.stringify({
        Name_app: 'SmartPro',
        id_object: context.id,
        id_login: this.constantParams.id_login,
        id_type: 10,
      });
      const send = async (blob: Blob) => {
        const formData = new FormData();
        formData.append('imgUploader', blob, context.id);
        formData.append('json', `${params}`);
        const upload = await Api.uploadFile(formData, 'UploadTM');
      };
      const blob = await fetch(context.file);
      if (blob) {
        const file = await blob.blob();
        send(file);
      }
    },

    // async fetchData(params: RequestParams): Promise<any> {
    //   const { Name_event } = params;
    //   const method = Name_event.startsWith('GET') ? 'GET' : 'POST';
    //   const data = { ...this.constantParams, ...params };
    //   const result = await api.all(method, data);
    //   if (result.errorType) {
    //     return result.errorText;
    //   }
    //   if (result.body) {
    //     return result.body;
    //   }
    //   return false;
    // },
  },
});
