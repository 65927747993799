
import { storeToRefs } from 'pinia';
// eslint-disable-next-line import/no-cycle
import { useStructStore } from '~store/struct/structModule';
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const structStore = useStructStore();
    const { struct } = storeToRefs(structStore);
    const columns = [
      { name: 'st1', label: 'st1', field: 'st1' },
      { name: 'st2', label: 'st2', field: 'st2' },
      { name: 'st3', label: 'st3', field: 'st3' },
      { name: 'st4', label: 'st4', field: 'st4' },
    ];
    const paginationSettings = {
      sortBy: 'desc',
      descending: false,
      rowsPerPage: 100,
    };
    const getStruct = () => {
      structStore.getModuleStruct('module_contacts');
    };

    return {
      getStruct,
      struct,
      columns,
      paginationSettings,
    };
  },

});

