
import { storeToRefs } from 'pinia';
import date from 'quasar/src/utils/date.js';import dom from 'quasar/src/utils/dom.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
// eslint-disable-next-line import/no-cycle
import { actionHandler } from '~actions/actionHandler';
import { Action, Button } from '~components/TableComponent/types';
import AppButton from '@/components/AppButton.vue';
import AppLoader from '@/components/AppLoader.vue';
// eslint-disable-next-line import/no-cycle
import { useOrgsStore } from '~store/orgs/orgsModule';
import { Org } from '~store/orgs/types';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '~store/auth/authModule';
// eslint-disable-next-line import/no-cycle
import { useManagerStore } from '~store/manager/managerModule';
// eslint-disable-next-line import/no-cycle
import { useDivisionStore } from '~store/division/divisionModule';
// eslint-disable-next-line import/no-cycle
import { usePatientStore } from '@/store/patient/patientModule';
// eslint-disable-next-line import/no-cycle
import { useTreeStore } from '~store/tree/treeModule';
import { TreeItem, StaffItem } from '~store/tree/types';
import SearchSelect from '@/components/SearchSelect.vue';
import SlideOption from '@/components/SlideOption.vue';
import OnBoarding from '@/components/Onboarding.vue';
import StructureTour from '@/components/StructureTour.vue';

import {
  defineComponent,
  reactive,
  computed,
  ref,
  onMounted,
  watch,
  onBeforeUpdate,
  nextTick,
} from 'vue';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '@/store';
import actionButtons from './TreeActionButtons.json';

type Item = TreeItem;

export default defineComponent({
  components: {
    SearchSelect,
    AppButton,
    AppLoader,
    SlideOption,
    OnBoarding,
    StructureTour,
  },
  setup() {
    const { style } = dom;
    const authStore = useAuthStore();
    const orgsStore = useOrgsStore();
    const treeStore = useTreeStore();
    const managerStore = useManagerStore();
    const patientStore = usePatientStore();
    const divisionStore = useDivisionStore();
    const { fullOrgList, currentOrg } = storeToRefs(orgsStore);
    const {
      tree,
      staffList,
      treeNode,
      selectedNodesMap,
      actualPatients,
      nodeProcessing,
      actualPatientsLength,
      pagin,
      patientSearch,
    } = storeToRefs(treeStore);
    const { processing, patientDocList } = storeToRefs(managerStore);

    const data = reactive({
      splitterModel: 50,
      typePost: false,
      actionButtons: actionButtons as Button[],
      search: '' as string,
      searchNode: '' as string,
      staffSearch: '' as string,
      resetState: null as object | null,
      selectedStaff: null as StaffItem | null,
      selectedPatient: null as any | null,
      slideSide: null as string | null,
      checkDirection: false,
      pinnedStaff: null as StaffItem | null,
      treePost: null as string | null,
      itemRefs: [] as HTMLDivElement[],
      tab: 'staff',
      draft: [] as any[],
      showDraft: false,
      showContacts: false,
      listsHeight: '',
      nextStepFlag: false,
      autoshow: true,
    });

    const $q = useQuasar();
    $q.loadingBar.setDefaults({
      color: 'teal',
      size: '5px',
      position: 'top',
    });

    const staffSlideItem = ref<any[]>([]);
    const patientSlideItem = ref<any[]>([]);
    const treeSelection = ref();
    const patientTab = ref();
    const selectedOrgRef = ref();
    const onboardingRef = ref();
    const structureTourRef = ref();
    const isNoobUser = ref();

    const flagAsProUser = () => {
      isNoobUser.value = false;
    };

    const flagAsNoobUser = () => {
      isNoobUser.value = true;
    };

    onBeforeUpdate(() => {
      staffSlideItem.value = [];
      patientSlideItem.value = [];
      data.listsHeight = `calc(100vh - ${style(treeSelection.value, 'height')} - 180px - ${(selectedNodesMap.value.length > 1 && data.tab !== 'patient') ? '35px' : '0px'})`;
    });

    onMounted(async () => {
      if (authStore.isAuth) {
        await orgsStore.setInitialOrg();
        if (fullOrgList.value && currentOrg.value) {
          const [value] = fullOrgList.value.filter((i: any) => i.id_org === currentOrg.value);
          selectedOrgRef.value.select(value);
        }
      }
      if (localStorage.getItem(`smartPro-${authStore.user.id_login}`)) {
        flagAsProUser();
      } else flagAsNoobUser();
      if (
        (isNoobUser.value && fullOrgList.value && fullOrgList.value.length)
      ) {
        nextTick(() => onboardingRef.value.start());
      }
    });

    watch(
      () => isNoobUser.value,
      (value) => {
        if (value) {
          localStorage.removeItem(`smartPro-${authStore.user.id_login}`);
        } else localStorage.setItem(`smartPro-${authStore.user.id_login}`, 'pro-user');
      },
    );

    watch(
      () => useMainStore().showOnboarding,
      (value) => {
        if (value) {
          flagAsNoobUser();
          nextTick(() => onboardingRef.value.start());
        }
      },
    );

    const getDocuments = async (id_patient: number, person = null) => {
      if (person) {
        // eslint-disable-next-line no-unused-expressions
        data.tab === 'staff' ? data.selectedStaff = person : data.selectedPatient = person;
      }
      $q.loadingBar.start();
      patientDocList.value = null;
      await managerStore.getPatientDocList(id_patient);
      $q.loadingBar.stop();
    };

    watch(
      () => (staffList.value.length || actualPatients.value.length) && data.tab,
      () => {
        data.listsHeight = `calc(100vh - ${style(treeSelection.value, 'height')} - 180px - ${(selectedNodesMap.value.length > 1 && data.tab !== 'patient') ? '35px' : '0px'})`;
      },
    );

    const checkOpenedOptions = () => {
      if (data.tab === 'staff') {
        staffSlideItem.value.forEach((i: any) => i?.close());
      }
      if (data.tab === 'patient') {
        patientSlideItem.value.forEach((i: any) => i?.close());
      }
    };

    const docDownload = async (doc: any) => {
      const path = await divisionStore.getPicPath(doc.id_document);
      const file = await Api.getFile(path[0].hash);
      if (file) {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([file as File]));
        link.href = url;
        link.setAttribute('download', `${doc.doc_name}.${path[0].extension}`);
        document.body.append(link);
        link.click();
        link.remove();
      }
    };

    const onPatientsLoad = async (index: number, done: any) => {
      if (index === 1 || actualPatients.value.length < 50) {
        pagin.value.p_start = index * 50;
        done();
        return;
      }
      await treeStore.getActualPatients(true);
      done();
      pagin.value.p_start = index * 50;
    };

    watch(
      () => patientSearch.value,
      async (val) => {
        if (val) {
          $q.loadingBar.start();
          await treeStore.getActualPatients();
          $q.loadingBar.stop();
        } else {
          actualPatients.value = [];
          $q.loadingBar.start();
          await treeStore.getActualPatients(false, true);
          $q.loadingBar.stop();
        }
      },
    );

    const docPreview = async (id_document: number) => {
      const path = await divisionStore.getPicPath(id_document);
      if (path[0].hash) {
        window.open(`https://host2.medsafe.tech/${path[0].hash}`, '_tab');
      }
    };

    const resetStaffSlide = (staff: any, index: number) => {
      data.selectedStaff = staff;
      staffSlideItem.value.forEach((i: any, refIndex: number) => {
        if (index !== refIndex) {
          i?.close();
        }
      });
    };

    const resetPatientSlide = (patient: any, index: number) => {
      data.selectedPatient = patient;
      patientSlideItem.value.forEach((i: any, refIndex: number) => {
        if (index !== refIndex) {
          i?.close();
        }
      });
    };

    const pinStaff = (staff: StaffItem) => {
      data.pinnedStaff = staff;
      data.typePost = false;
      treeNode.value = '0';
      data.staffSearch = '';
      patientSearch.value = '';
      selectedNodesMap.value = [];
      staffList.value = [];
      treeStore.getOrgTreePart();
    };

    const unpinStaff = async () => {
      if (data.pinnedStaff) {
        treeNode.value = '0';
        selectedNodesMap.value = [];
        const id_tp = data.pinnedStaff.id_tree_people;
        data.pinnedStaff = null;
        staffList.value = [];
        data.typePost = false;
        data.treePost = null;
        await treeStore.getOrgTreePart();
        managerStore.getTreePathToStaff(id_tp);
      }
    };

    const moveStaff = async () => {
      if (data.treePost && data.pinnedStaff) {
        $q.loadingBar.start();
        await managerStore.changeStaffPost(data.treePost, data.pinnedStaff?.id_tree_people);
        data.pinnedStaff = null;
        treeStore.pagin.p_start = 0;
        treeStore.getTreeStaffList();
        $q.loadingBar.stop();
      }
    };

    const copyStaff = async () => {
      if (data.treePost && data.pinnedStaff) {
        $q.loadingBar.start();
        await managerStore.changeStaffPost(data.treePost, data.pinnedStaff?.id_tree_people, 1);
        data.pinnedStaff = null;
        treeStore.pagin.p_start = 0;
        treeStore.getTreeStaffList();
        $q.loadingBar.stop();
      }
    };

    const filteredStaffList = computed(() => {
      if (data.staffSearch) {
        return staffList.value.filter(
          (i: StaffItem) => JSON.stringify(i).toLowerCase()
            .includes(data.staffSearch.toLowerCase()),
        );
      }
      return staffList.value;
    });

    const filteredPatientList = computed(() => actualPatients.value);

    const nodeActions = computed(() => data.typePost && !data.pinnedStaff);

    const staffActions = computed(() => data.typePost && !!data.pinnedStaff);

    const setSearchString = ({ needle, field }: { needle: string; field: string }) => {
      data.search = needle;
    };
    const setSearchTreeString = ({ needle, field }: { needle: string, field: string }) => {
      data.searchNode = needle;
    };

    const showVisits = () => {
      const action = {
        name: 'PatientVisitsModal',
        type: 'modal',
        customParam: { ...data.selectedStaff, maximized: true },
      };
      actionHandler(action as Action);
    };

    const showEditStaffModal = () => {
      const action = {
        name: 'EditStaffModal',
        type: 'modal',
        customParam: { ...data.selectedStaff, maximized: true },
      };
      actionHandler(action as Action);
    };

    const addPatientForce = () => {
      data.showDraft = false;
      data.draft = [];
      const action = {
        name: 'PatientAddModal',
        type: 'modal',
        customParam: { ...data.selectedStaff, maximized: true },
      };
      actionHandler(action as Action);
    };

    const addPatient = () => {
      const draft = actualPatients.value.filter(
        (i: any) => i.id_people === data.selectedStaff?.id_people && i.status === 'Черновик',
      );
      if (draft.length) {
        data.showDraft = true;
        data.draft = draft;
        return;
      }
      const action = {
        name: 'PatientAddModal',
        type: 'modal',
        customParam: { ...data.selectedStaff, maximized: true },
      };
      actionHandler(action as Action);
    };

    const continuePatient = async (patient: any) => {
      $q.loadingBar.start();
      const patientParams = await patientStore.findTempPatient(patient.id_patient);
      const result = {
        id_patient: patientParams.id_patient,
        id_people: patientParams.id_people,
        id_tree: patientParams.id_tree,
        id_tree_people: patientParams.id_tree_people,
      };
      const action = {
        name: 'EditReferralModal',
        type: 'modal',
        customParam: { ...patientParams, result, maximized: true },
      };
      actionHandler(action as Action);
      data.showDraft = false;
      data.draft = [];
      $q.loadingBar.stop();
    };

    const showContacts = async (document = undefined) => {
      const selectedRowData = data.tab === 'staff' ? data.selectedStaff : data.selectedPatient;
      const params: any = {
        ...selectedRowData,
        maximized: true,
      };
      console.log(params);
      if (document) {
        params.document = document;
      }
      console.log(params);
      const action = {
        name: 'ContactsModal',
        type: 'modal',
        customParam: params,
      };
      actionHandler(action as Action);
    };

    const changeType = (item: Item | boolean) => {
      if (typeof item === 'boolean') {
        data.typePost = item;
      } else data.typePost = item.id_type === 2;
      if (staffList.value.length && !data.typePost) {
        staffList.value = [];
      }
    };

    const selectOrg = async (value: any) => {
      orgsStore.setCurrentOrg(value.id_org);
      treeNode.value = '0';
      pagin.value.p_start = 0;
      data.staffSearch = '';
      patientSearch.value = '';
      selectedNodesMap.value = [];
      actualPatients.value = [];
      actualPatientsLength.value = null;
      staffList.value = [];
      treeStore.getOrgTreePart();
      patientStore.getMatrixDict();
    };
    const removeOrg = () => {
      currentOrg.value = null;
      treeNode.value = '0';
      data.staffSearch = '';
      patientSearch.value = '';
      selectedNodesMap.value = [];
      actualPatients.value = [];
      staffList.value = [];
      actualPatientsLength.value = null;
      changeType(false);
    };

    const removeNode = (index: number) => {
      actualPatients.value = [];
      actualPatientsLength.value = null;
      staffList.value = [];
      selectedNodesMap.value.splice(index + 1);
      treeNode.value = selectedNodesMap.value[index].id_tree;
      if (selectedNodesMap.value[index - 1]) {
        tree.value = selectedNodesMap.value[index].nodeEntries;
      }
      if (selectedNodesMap.value.length > 1) treeStore.getTreeStaffList();
      treeStore.getActualPatients(false, true);
      patientStore.getMatrixDict();
    };

    const findTreepathToStaff = (staff: StaffItem) => {
      if (data.typePost) return;
      data.autoshow = false;
      $q.loadingBar.start();
      // onboardingRef.value.finish();
      data.nextStepFlag = true;
      managerStore.getTreePathToStaff(staff.id_tree_people);
      $q.loadingBar.stop();
      if (data.typePost) data.autoshow = true;
    };

    watch(
      () => staffList.value,
      (value) => {
        if (
          data.typePost
          && value.length
          && data.nextStepFlag
          && isNoobUser.value
        ) {
          structureTourRef.value.start();
          data.nextStepFlag = false;
        }
      },
    );

    const findStaffByPatient = async (patient: any) => {
      data.tab = 'staff';
      treeNode.value = '0';
      selectedNodesMap.value = [];
      staffList.value = [];
      data.typePost = false;
      data.treePost = null;
      data.staffSearch = patient.fio;
      await treeStore.getOrgTreePart();
      console.log(staffList.value);
      managerStore.getTreePathToStaff(patient.id_tree_people);
    };

    const removePatient = (item: any) => {
      patientStore.deletePatient(item.id_patient);
    };

    const cancelPatient = (item: any) => {
      patientStore.cancelPatient(item.id_patient);
    };

    const globalSearch = async () => {
      $q.loadingBar.start();
      treeNode.value = '0';
      selectedNodesMap.value = [];
      staffList.value = [];
      data.typePost = false;
      data.treePost = null;
      await treeStore.getOrgTreePart();
      treeStore.pagin.p_start = 0;
      treeStore.getTreeStaffList(data.staffSearch);
      $q.loadingBar.stop();
    };

    const defineStaffAction = (action: string, item: any): void => {
      switch (action) {
        case 'addPatient': addPatient(); break;
        case 'pinStaff': pinStaff(item); break;
        case 'showVisits': showVisits(); break;
        case 'showContacts': showContacts(); break;
        case 'removeStaff': return;
        case 'showEditStaffModal': showEditStaffModal(); break;
        default: useMainStore().showMessage('Неизвестное действие');
      }
    };

    const definePatientAction = (action: string, item: any) => {
      switch (action) {
        case 'continuePatient': continuePatient(item); break;
        case 'cancelPatient': cancelPatient(item); break;
        case 'removePatient': removePatient(item); break;
        default: useMainStore().showMessage('Неизвестное действие');
      }
    };

    const select = (item: Item) => {
      patientSearch.value = '';
      let flag = -1;
      selectedNodesMap.value.forEach((i, index) => {
        if (flag > -1) return flag;
        if (index !== selectedNodesMap.value.length - 1) {
          i.nodeEntries.forEach((k) => {
            if (
              k.id_tree === selectedNodesMap.value[index + 1].id_tree
              && k.id_parent === item.id_parent
            ) {
              flag = index;
            }
          });
        }
        return flag;
      });
      if (flag > -1) {
        selectedNodesMap.value.splice(flag + 1);
        if (staffList.value.length) {
          staffList.value = [];
        }
        tree.value = selectedNodesMap.value[flag].nodeEntries;
        patientStore.getContractsMatrix(selectedNodesMap.value[flag].id_tree);
      }
      treeStore.setCurrentItem(item.id_tree);
      data.actionButtons = actionButtons.map((btn) => ({
        ...btn,
        disable: false,
      })) as Button[];
      const addBtn = data.actionButtons.find((btn) => btn.action.customParam === 'addTreeItem');
      const hazadrsBtn = data.actionButtons.find((btn) => btn.action.name === 'HazardsModal');
      const cloneBtn = data.actionButtons.find((btn) => btn.action.customParam === 'cloneTreeItem');
      const currentItem = tree.value.find((i: Item) => i?.id_tree === item?.id_tree);

      if ((!item.id_type || item.id_type === 1) && item.has_children) {
        data.typePost = false;
        data.treePost = null;
        treeStore.getOrgTreePart();
        treeStore.getTreeStaffList();
      } else if ((item.id_type && item.id_type > 1) && !(item.has_children)) {
        data.typePost = true;
        data.treePost = item.id_tree;
        treeStore.pagin.p_start = 0;
        treeStore.getTreeStaffList();
      }

      switch (item?.id_type) {
        case 0: {
          data.actionButtons = data.actionButtons.map((btn) => ({
            ...btn, disable: true,
          }));
          if (addBtn) {
            addBtn.disable = false;
          }
          console.log(addBtn);
          break;
        }
        case 1: {
          if (hazadrsBtn) {
            hazadrsBtn.disable = true;
          }
          console.log(addBtn);
          break;
        }
        case 2: {
          if (cloneBtn) {
            cloneBtn.disable = true;
          }
          if (addBtn) {
            addBtn.action = {
              name: 'EmployeeAddModal',
              type: 'modal',
              customParam: { ...currentItem },
            };
          }
          console.log(addBtn);
          break;
        }
        default:
          break;
      }

      if (currentItem) {
        data.actionButtons = data.actionButtons.map((btn) => ({
          ...btn,
          action: {
            ...btn.action,
            customParam: {
              ...currentItem,
              type: btn.action.customParam,
              mode: 'full',
              maximized: true,
            },
          },
        }));
        console.log(data.actionButtons);
      }
    };

    watch(() => data.staffSearch, (val): void => {
      if (val && !data.typePost && val.length >= 2) {
        treeStore.pagin.p_start = 0;
        $q.loadingBar.start();
        treeStore.getTreeStaffList(val);
        $q.loadingBar.stop();
      }
    });

    watch(
      () => actualPatients.value,
      (value) => {
        if (data.draft.length) {
          const draft = value.filter(
            (i: any) => i.id_people === data.selectedStaff?.id_people && i.status === 'Черновик',
          );
          if (draft.length) {
            data.draft = draft;
          } else {
            data.showDraft = false;
          }
        }
      },
      {
        deep: true,
      },
    );

    const onAction = (action: Action) => {
      actionHandler(action);
    };

    const setItemRef = (el: any) => {
      if (el) {
        data.itemRefs.push(el);
      }
    };

    const revealLeftStaffMenu = () => {
      staffSlideItem.value[0].showMenu('right');
    };

    const revealRightStaffMenu = () => {
      staffSlideItem.value[0].showMenu('left');
    };

    const closeMenu = () => {
      staffSlideItem.value[0].close();
    };

    onBeforeUpdate(() => {
      data.itemRefs = [];
    });

    return {
      setItemRef,
      fullOrgList,
      data,
      tree,
      staffList,
      select,
      onAction,
      treeNode,
      setSearchString,
      selectOrg,
      setSearchTreeString,
      selectedNodesMap,
      filteredStaffList,
      showVisits,
      findTreepathToStaff,
      findStaffByPatient,
      processing,
      changeType,
      globalSearch,
      pinStaff,
      nodeActions,
      staffActions,
      unpinStaff,
      moveStaff,
      copyStaff,
      addPatient,
      showEditStaffModal,
      actualPatients,
      removePatient,
      filteredPatientList,
      continuePatient,
      currentOrg,
      removeOrg,
      removeNode,
      date,
      addPatientForce,
      cancelPatient,
      getDocuments,
      patientDocList,
      docDownload,
      docPreview,
      showContacts,
      defineStaffAction,
      definePatientAction,
      resetStaffSlide,
      resetPatientSlide,
      staffSlideItem,
      patientSlideItem,
      treeSelection,
      checkOpenedOptions,
      nodeProcessing,
      actualPatientsLength,
      onPatientsLoad,
      pagin,
      patientTab,
      patientSearch,
      selectedOrgRef,
      onboardingRef,
      structureTourRef,
      revealLeftStaffMenu,
      revealRightStaffMenu,
      closeMenu,
      isNoobUser,
    };
  },
});
