
import { defineComponent, reactive, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~store/auth/authModule';

export default defineComponent({
  setup() {
    const data = reactive({
      login: '',
      password: '',
    });
    const authStore = useAuthStore();
    const isLoading = ref(false);
    const isPwd = ref(true);

    const onSubmit = () => {
      authStore.signIn(data.login, data.password);
    };
    const onReset = () => {
      data.login = '';
      data.password = '';
    };

    return {
      data,
      onSubmit,
      onReset,
      isPwd,
    };
  },
});
