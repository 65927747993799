import { IContactOption } from '@/types';

export const NAME_APP = 'SmartPRO';
export enum modules {
  webmanagers = 'WEB_managers',
  calculation = 'module_calculation',
  contacts = 'module_contacts',
  medAdmin = 'medAdmin',
  covid = 'Covid',
  division = 'division',
}
export const MESSAGE_DELAY = 5000;

export const LSKeys = {
  pins: 'medsafe-pro-pins',
};

export const height = 109;
export const tabsHeaderHeight = 68;
export const employeesTabsHeaderOffset = 36;

export const otherMissionId = 5;

export const contactTypes: IContactOption[] = [
  {
    id: 1,
    label: 'Телефон',
  },
  {
    id: 2,
    label: 'E-mail',
  },
  // {
  //   id: 3,
  //   label: 'Telegram',
  // },
  {
    id: 4,
    label: 'Учетка',
  },
];

export const contactBelongs: IContactOption[] = [
  {
    id: 0,
    label: 'Личный',
  },
  {
    id: 1,
    label: 'Корпоративный',
  },
  {
    id: 2,
    label: 'Личный и корпоративный',
  },
];

export const contractTemplateParameters = [
  {
    id: '0',
    text: 'Ничего не делать',
  },
  {
    id: '1',
    text: 'Добавить новые услуги',
  },
  {
    id: '2',
    text: 'обновить цены у имеющихся услуг',
  },
  {
    id: '3',
    text: 'полностью поменять прайс',
  },
];

export const requiredFieldRule = [(val: string | object) => !!val || 'Это обязательное поле!'];

export const delayedQueryDelay = 2000;

export const MAX_UNPAGINATED_TABLE_SIZE = 50;

export const MESSAGE_SHOW_DURATION = 5000;

export const DADATA_REQUEST_COUNT = 300;
