import script from "./SearchSelect.vue?vue&type=script&setup=true&lang=ts"
export * from "./SearchSelect.vue?vue&type=script&setup=true&lang=ts"

import "./SearchSelect.vue?vue&type=style&index=0&id=03b5b366&lang=css"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QIcon,QField});
