// import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '~store/index';
// eslint-disable-next-line import/no-cycle
import { useTreeStore } from '~store/tree/treeModule';
// eslint-disable-next-line import/no-cycle
import { useOrgsStore } from '~store/orgs/orgsModule';
// import { TreeItem } from '~store/tree/types';
// eslint-disable-next-line import/no-cycle
import { usePatientStore } from '../patient/patientModule';
import {
  Contracts,
  HazardNode,
  Hazard,
  Visits,
  DocumentList,
  Contact,
} from './types';

const MODULE_WEB_MANAGERS = 'WEB_managers';
// const treeStore = useTreeStore();

export const useManagerStore = defineStore('manager', {
  state: () => ({
    contracts: null as any | Contracts[],
    hazardNode: null as any | HazardNode[],
    hazardCatalogue: null as any | Hazard[],
    patientVisits: null as any | Visits[],
    patientDocList: null as any | DocumentList[],
    contacts: null as any | Contact[],
    processing: false as boolean,
    responsiblings: [] as any[],
    sendDocLog: [] as any[],
    contractsList: [] as any[],
    staffSignList: [] as any[],
  }),
  getters: {},
  actions: {
    async getContractsInTree(id_tree: string | number): Promise<void> {
      const params = {
        Name_event: 'GET:contracts_in_tree',
        Name_module: MODULE_WEB_MANAGERS,
        id_tree,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.contracts = data;
        }
      }
    },

    async getHazardNode(id_tree: string): Promise<void> {
      const params = {
        Name_event: 'GET:hazard_node',
        Name_module: MODULE_WEB_MANAGERS,
        id_tree,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.hazardNode = data;
        }
      }
    },

    async getHazardCatalogue(id_tree: string, legal_instrument = 0): Promise<void> {
      const params = {
        Name_event: 'GET:hazard_catalogue_param',
        Name_module: MODULE_WEB_MANAGERS,
        id_tree,
        legal_instrument,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.hazardCatalogue = data;
        }
      }
    },

    async getPatientVisits(id_people: number): Promise<void> {
      const params = {
        Name_event: 'GET:patient_list',
        Name_module: MODULE_WEB_MANAGERS,
        id_people,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.patientVisits = data;
        }
      }
    },

    async getPatientDocList(id_patient: number): Promise<void> {
      const params = {
        Name_event: 'GET:doc_patient_list',
        Name_module: MODULE_WEB_MANAGERS,
        id_patient,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.patientDocList = data;
        }
      }
    },

    async getSendDocLog(id_doc: number): Promise<void> {
      const params = {
        Name_event: 'GET:doc_send_log',
        Name_module: 'module_contacts',
        id_doc,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.sendDocLog = data;
        }
      }
    },

    async getContactsList(id_patient: number | null, id_people: number | null): Promise<void> {
      const params = {
        Name_event: 'GET:contacts_list',
        Name_module: 'module_contacts',
        id_patient,
        id_people,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.contacts = data;
        }
      }
    },

    async getResponsiblings(): Promise<void> {
      const params = {
        Name_event: 'GET:responsiblings',
        Name_module: 'module_calculation',
        id_org: useOrgsStore().currentOrg ?? 0,
        id_people: 0,
        what_for: 0,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.responsiblings = data;
        }
      }
    },

    async getContractsList(): Promise<void> {
      const params = {
        Name_event: 'GET:contracts_list',
        Name_module: 'module_calculation',
        id_org: useOrgsStore().currentOrg ?? 0,
        archive: 2,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.contractsList = data;
        }
      }
    },

    async getStaffSingList(): Promise<void> {
      const params = {
        Name_event: 'GET:staff_sign_list',
        Name_module: 'SmartPRO',
        id_org: useOrgsStore().currentOrg ?? 0,
        id_tree: useTreeStore().treeNode,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          this.staffSignList = data;
        }
      }
    },

    async sendDocument(context: any): Promise<void> {
      const params = {
        Name_event: 'POST:send_doc_to_contact',
        Name_module: 'module_contacts',
        copy_me: context.copy_me,
        id_contact: context.id_contact,
        id_doc: context.id_doc,
        txt_note: context.txt_note,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          const [msg] = data;
          useMainStore().showMessage(msg.err_txt);
          this.getSendDocLog(context.id_doc);
        }
      }
    },

    async editContractNote(context: any): Promise<void> {
      const params = {
        Name_event: 'POST:contract_note',
        Name_module: MODULE_WEB_MANAGERS,
        id_contract: context.id_contract,
        json_str: JSON.stringify({
          note_for_patient: context.note_for_patient,
        }),
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          const [msg] = data;
          useMainStore().showMessage(msg.err_txt);
        }
      }
    },

    async addContact(context: any): Promise<void> {
      const params = {
        Name_event: 'POST:contact_add',
        Name_module: 'module_contacts',
        type_contact: '2',
        id_patient: context.id_patient,
        id_people: context.id_people,
        txt_contact: context.txt_contact,
        txt_note: context.txt_note ?? '',
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          console.log(data);
        }
      }
    },

    async addContactCorporate(context: any): Promise<void> {
      const params = {
        Name_event: 'POST:contact_add_for_org',
        Name_module: 'module_contacts',
        type_contact: '2',
        id_patient: context.id_patient,
        id_people: context.id_people,
        txt_contact: context.txt_contact,
        txt_note: context.txt_note ?? '',
        corporate: context.corporate,
        id_org: useOrgsStore().currentOrg ?? 0,
        id_tree: context.id_tree,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          console.log(data);
        }
      }
    },

    async correctContact(context: any): Promise<void> {
      const params = {
        Name_event: 'POST:contact_correct',
        Name_module: 'module_contacts',
        type_contact: '2',
        id_contact: context.id_contact,
        type_correct: context.type_correct,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          console.log(data);
        }
      }
    },

    async setContactNote(context: any): Promise<void> {
      const params = {
        Name_event: 'POST:contact_note',
        Name_module: 'module_contacts',
        id_contact: context.id_contact,
        txt_note: context.txt_note,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          console.log(data);
        }
      }
    },

    async changeContactBelong(context: any): Promise<void> {
      const params = {
        Name_event: 'POST:contact_corporate',
        Name_module: 'module_contacts',
        type_contact: '2',
        id_contact: context.id_contact,
        corporate: context.corporate,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          console.log(data);
        }
      }
    },

    async changeStaffPost(id_tree: string, id_tree_people: number, type = 0): Promise<void> {
      const params = {
        Name_event: 'POST:staff_move',
        Name_module: MODULE_WEB_MANAGERS,
        id_tree,
        id_tree_people,
        type,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          console.log(data);
        }
      }
    },

    async getTreePathToStaff(id_tree_people: number): Promise<void> {
      this.processing = true;
      const store = useTreeStore();
      const params = {
        Name_event: 'GET:tree_by_TreePeople',
        Name_module: MODULE_WEB_MANAGERS,
        id_tree_people,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          useTreeStore().clearNodeMap();
          /* eslint-disable-next-line */
          for await (const i of data) {
            store.treeNode = i.id_tree;
            if (i.id_type !== 2) {
              await store.getOrgTreePart();
            } else {
              await store.getTreeStaffList();
            }
          }
        }
      }
      this.processing = false;
    },

    async contractBranchBinding(id_branch: number, id_contract: number, param = 1): Promise<void> {
      const params = {
        Name_event: 'POST:available_branches',
        Name_module: MODULE_WEB_MANAGERS,
        id_branch,
        id_contract,
        param,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.post(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          await usePatientStore()
            .getContractsMatrix(
              useTreeStore().selectedNodesMap[useTreeStore().selectedNodesMap.length - 1].id_tree,
            );
        }
      }
    },
  },
});
