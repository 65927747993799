import './styles/quasar.scss';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import LoadingBar from 'quasar/src/plugins/LoadingBar.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    LoadingBar,
  },
};
