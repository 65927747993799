// import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { useMainStore } from '~store/index';
// import { useAuthStore } from '~store/auth/authModule';
import { Struct } from './types';

// const api = new Api();

export const useStructStore = defineStore('struct', {
  state: () => ({
    struct: null as any | Struct[],
  }),
  getters: {},
  actions: {
    async getModuleStruct(moduleName: string): Promise<void> {
      const params = {
        Name_event: 'GET:this_struct',
        Name_module: moduleName,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        this.struct = result.data;
      }
    },
  },
});
