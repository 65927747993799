import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.getStruct && _ctx.getStruct(...args)))
    }, "Забрать"),
    (_ctx.struct)
      ? (_openBlock(), _createBlock(_component_q_table, {
          key: 0,
          flat: "",
          bordered: "",
          title: "Структура модуля",
          rows: _ctx.struct,
          "row-key": "st1",
          pagination: _ctx.paginationSettings
        }, null, 8, ["rows", "pagination"]))
      : _createCommentVNode("", true)
  ]))
}