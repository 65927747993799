// import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
// eslint-disable-next-line import/no-cycle
import Api from '~utils/Api';
// eslint-disable-next-line import/no-cycle
import { useMainStore } from '~store/index';
// eslint-disable-next-line import/no-cycle
import { useOrgsStore } from '~store/orgs/orgsModule';
// eslint-disable-next-line import/no-cycle
import { usePatientStore } from '~store/patient/patientModule';
import { TreeItem, StaffItem, TreeNode } from './types';

export const useTreeStore = defineStore('tree', {
  state: () => ({
    tree: null as any | TreeItem[],
    staffList: [] as any | StaffItem[],
    treeNode: '' as string,
    selectedNodesMap: [] as TreeNode[],
    actualPatients: [] as any[],
    actualPatientsLength: null as number | null,
    nodeProcessing: false,
    underControl: null as any,
    pagin: {
      p_start: 0,
      p_lenght: 50,
    },
    patientSearch: '',
  }),
  getters: {},
  actions: {
    async getOrgTreePart(searchPath = false): Promise<void | boolean> {
      const params = {
        Name_event: 'GET:tree_v2',
        Name_module: 'WEB_managers',
        id_tree: this.treeNode,
        id_org: useOrgsStore().currentOrg ?? 0,
        ...useMainStore().getConstantParams,
      };
      try {
        this.nodeProcessing = true;
        const result = await Api.get(params);
        if (!result.errorType) {
          const { data } = result;
          if (data) {
            this.tree = data;
            this.selectedNodesMap.push({
              id_tree: this.treeNode,
              nodeEntries: this.tree,
            });
            usePatientStore().getContractsMatrix(this.treeNode);
            this.getActualPatients(false, true);
          }
          if (searchPath) {
            return true;
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.nodeProcessing = false;
      }
      return false;
    },

    async getTreeStaffList(search_string = '', type_drop = 0): Promise<void> {
      if (this.treeNode === '0' && !this.selectedNodesMap.length) {
        return;
      }
      const params = {
        Name_event: 'GET:tree_staff_list',
        Name_module: 'WEB_managers',
        search_string,
        type_drop,
        // eslint-disable-next-line no-nested-ternary
        id_tree: this.treeNode !== '0' ? this.treeNode : this.selectedNodesMap[0].nodeEntries[0]?.id_tree,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          data.forEach((i) => {
            const tmp = i?.id_patient ? JSON.parse(i?.id_patient) : 0;
            // eslint-disable-next-line no-param-reassign
            i.id_patient = tmp;
          });
          this.staffList = data;
          this.getActualPatients(false, true);
        }
      }
    },

    async getActualPatients(lazy = false, newNode = false): Promise<void> {
      if (this.treeNode === '0' && !this.selectedNodesMap.length) {
        return;
      }
      if (this.underControl) {
        this.underControl.abort();
      }
      if (newNode || this.patientSearch) {
        this.pagin.p_start = 0;
      }
      this.underControl = new AbortController();
      const { signal } = this.underControl;
      const params = {
        Name_event: 'GET:patient_by_tree',
        Name_module: 'SmartPRO',
        id_org: useOrgsStore().currentOrg ?? 0,
        // eslint-disable-next-line no-nested-ternary
        id_tree: this.treeNode !== '0' ? this.treeNode : this.selectedNodesMap[0].nodeEntries[0]?.id_tree,
        search_str: JSON.stringify({
          fio: this.patientSearch,
        }),
        json_params: JSON.stringify({
          paging: {
            p_start: this.pagin.p_start,
            p_lenght: this.pagin.p_lenght,
            sort_type: 0,
          },
        }),
        ...useMainStore().getConstantParams,
      };
      this.actualPatientsLength = null;
      const result = await Api.get(params, signal);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          const filtered = data.filter((i: any) => !!i.id_patient);
          if (lazy && !newNode) {
            this.actualPatients.push(...filtered);
            this.actualPatientsLength = data.length;
          } else if (!lazy && newNode) {
            this.actualPatients = filtered;
            this.actualPatientsLength = data.length;
          } else if (!lazy && !newNode) {
            this.actualPatients = filtered;
            this.actualPatientsLength = this.actualPatients.length;
          }
        }
      }
    },

    setCurrentItem(item: string) {
      this.treeNode = item;
    },

    clearNodeMap(): void {
      this.selectedNodesMap = [];
    },

  },
});
