// import { TableStructure } from '~components/TableComponent/types';
import { defineStore } from 'pinia';
import Api from '~utils/Api';
import { useMainStore } from '~store/index';
// import { useAuthStore } from '~store/auth/authModule';

// const api = new Api();

const MODULE_WEB_DIVISION = 'WEB_division';

export const useDivisionStore = defineStore('division', {
  state: () => ({

  }),
  getters: {},
  actions: {
    async getPicPath(id_doc: number): Promise<any> {
      const params = {
        Name_event: 'get_pic_path',
        Name_module: MODULE_WEB_DIVISION,
        id_doc,
        ...useMainStore().getConstantParams,
      };
      const result = await Api.get(params);
      if (!result.errorType) {
        const { data } = result;
        if (data) {
          return data;
        }
      }
      return [];
    },

  },
});
