import { RouteRecordRaw } from 'vue-router';
import LoginView from '~views/LoginView.vue';
import PageNotFound from '~views/PageNotFound.vue';
import DashboardView from '~views/DashboardView.vue';
import StructView from '~views/StructView.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
  },
  {
    path: '/',
    redirect: '/login',
    name: 'home',
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/struct',
    name: 'Struct',
    component: StructView,
    meta: { requiresAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true },
  },
];

export default routes;
